<template>
  <b-row>
    <b-col>
      <b-card title="Utility meter types">
        <blueprint-data api-route-path="inventory/utilitymetertypes" :fields="fields">
          <template v-slot:default="{ state, item }">
            <b-form-group label="Name" label-for="name">
              <b-form-input id="name" v-model="item.name" :state="state('name')" type="text" placeholder="Name" />
            </b-form-group>
            <b-form-group label="Unit" label-for="unit">
              <b-form-input id="unit" v-model="item.unit" :state="state('unit')" type="text" placeholder="Unit (eg. kWh)" />
            </b-form-group>
          </template>
        </blueprint-data>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>

export default {
  data () {
    return {
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'unit',
          sortable: true,
        },
      ],
    };
  },
};
</script>
